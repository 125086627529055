import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button } from '@material-ui/core';
import { Send } from '@material-ui/icons';

import "../CSS/AddYearlyGoalScreen.css";
import "react-datepicker/dist/react-datepicker.css";

import { connect } from 'react-redux';

import { addYearlyGoal, updateYearlyGoal } from '../../../../../actions/yearlyGoalActions'
import { numberStepIntro } from '../../../../../actions/authActions'
// color
import { CirclePicker } from 'react-color';

const colors = [
    '#eae4e9',
    '#fff1e6',
    '#fde2e4',
    '#fad2e1',
    '#e2ece9',
    '#bee1e6',
    '#f0efeb',
    '#dfe7fd',
    '#cddafd',
]
const AddYearlyGoalScreen = ({ yearlyGoal, setYearlyGoal, history, addYearlyGoal, updateYearlyGoal, isUptate, setIsUpdate, locationComponent, activeStep, setActiveStep, numberStepIntro, setOpenBackdrop, isAddComponent }) => {
    const auth = useSelector(state => state.auth.user);
    const goal = useSelector(state => state.yearlyGoal);

    const submitHandler = (e) => {
        // Check if it's an update or a add (if _id exists, then it's an update)
        e.preventDefault();
        setOpenBackdrop(true)
        if (yearlyGoal._id) {
            const id = yearlyGoal._id

            /*setYearlyGoal({
                ...yearlyGoal,
                name: yearlyGoal.name,
                estimatedNumberedGoal: yearlyGoal.estimatedNumberedGoal,
                realNumberedGoal: yearlyGoal.realNumberedGoal,
                note: yearlyGoal.note,
                percentageAchieved: yearlyGoal.percentageAchieved,
            })*/

            const updatedYearlyGoal = {
                ...yearlyGoal,
                name: yearlyGoal.name,
                estimatedNumberedGoal: yearlyGoal.estimatedNumberedGoal,
                realNumberedGoal: yearlyGoal.realNumberedGoal,
                note: "",
                percentageAchieved: yearlyGoal.percentageAchieved,
                comment: yearlyGoal.comment,
                color: yearlyGoal.color,
            }

            /*setYearlyGoal({
                ...yearlyGoal,
                name: yearlyGoal.name,
                estimatedNumberedGoal: yearlyGoal.estimatedNumberedGoal,
                realNumberedGoal: yearlyGoal.realNumberedGoal,
                note: "",
                percentageAchieved: yearlyGoal.percentageAchieved,
            })*/

            updateYearlyGoal(updatedYearlyGoal, id, 1);
        } else {
            /*setYearlyGoal({
                ...yearlyGoal,
                name: yearlyGoal.name,
                estimatedNumberedGoal: yearlyGoal.estimatedNumberedGoal,
                realNumberedGoal: yearlyGoal.realNumberedGoal,
                note: "",
                percentageAchieved: yearlyGoal.percentageAchieved,
            })*/

            const updatedYearlyGoal = {
                ...yearlyGoal,
                name: yearlyGoal.name,
                estimatedNumberedGoal: yearlyGoal.estimatedNumberedGoal,
                realNumberedGoal: yearlyGoal.realNumberedGoal,
                note: "",
                percentageAchieved: yearlyGoal.percentageAchieved,
                comment: yearlyGoal.comment,
                color: yearlyGoal.color,
            }

            // Dispatch the goal using the action created in store
            addYearlyGoal(updatedYearlyGoal, yearlyGoal.type);
        }

        // Reset todo values
        /*setYearlyGoal({
            ...yearlyGoal,
            name: "",
            estimatedNumberedGoal: null,
            realNumberedGoal: null,
            note: "",
            percentageAchieved: yearlyGoal.percentageAchieved,
        })*/

        setTimeout(() => {
            if (locationComponent == "Intro") {
                setActiveStep((activeStep) => activeStep + 1);
                const updatedStep = {
                    _id: auth._id,
                    stepIntro: 3,
                };
                numberStepIntro(updatedStep);
                //setOpenBackdrop(false);
            } else {
                const oneModaltodo = document.querySelector(".modal__views");
                oneModaltodo.classList.remove("active-modal");
            }

        }, 2000);
    }

    useEffect(() => {
        if (isAddComponent == true && locationComponent != "Intro") {
            setYearlyGoal({
                type: 1,
                estimatedNumberedGoal: "",
                name: "",
                realNumberedGoal: 0,
                note: "",
                percentageAchieved: 0,
                active: true,
                deleteBtn: true,
                comment: "",
                color: "",
            });
            setIsUpdate(false)
        }
    }, [isAddComponent, goal]);


    const [state, setState] = useState("#fff")

    return (
        <>
            <form autoComplete="off" className="goal__add-Form" onSubmit={submitHandler}>

                <div className="goal__input">
                    <TextField
                        id="estimatedNumber"
                        label="Nombre"
                        variant="outlined"
                        type="number"
                        autoFocus
                        fullWidth
                        step="1"
                        value={yearlyGoal.estimatedNumberedGoal}
                        onChange={(e) => setYearlyGoal({ ...yearlyGoal, estimatedNumberedGoal: Math.round(e.target.value) })}
                        required
                        helperText="ex: 15 "
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                    />

                    <TextField
                        id="name"
                        label="Objectif"
                        variant="outlined"
                        fullWidth
                        value={yearlyGoal.name}
                        onChange={(e) => setYearlyGoal({ ...yearlyGoal, name: e.target.value })}
                        InputProps={{
                            readOnly: isUptate,
                        }}
                        required
                        helperText="% masse graisseuse"
                    />


                </div>

                <div className="contact__label">
                    <TextField
                        id="yearlyComment"
                        label="Commentaire"
                        multiline
                        rowsMax="3"
                        value={yearlyGoal.comment}
                        onChange={(e) => setYearlyGoal({ ...yearlyGoal, comment: e.target.value })}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                </div>
                <span>Couleur</span>
                <CirclePicker
                    className="goal__color"
                    color={state}
                    colors={colors}
                    onChangeComplete={(e) => setYearlyGoal({ ...yearlyGoal, color: e.hex })}
                />

                <Button variant="contained" color="primary" className="submitButton" type="submit">
                    <Send />
                </Button>

            </form>
        </>
    )
}

export default connect(null, { addYearlyGoal, updateYearlyGoal, numberStepIntro })(AddYearlyGoalScreen);