import React, { useState, useEffect, Component } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Button } from "@material-ui/core";
import { Create, Delete, CheckCircle, PlayCircleFilled, StopSharp } from "@material-ui/icons";
import CancelIcon from '@material-ui/icons/Cancel';
import moment from "moment";

import { connect } from 'react-redux';

import "../CSS/TodoScreen.css";
import { getTodos, startTodo, stopTodo, pauseTodo, deleteTodo } from '../../../../actions/todoActions';
import { getPoints } from '../../../../actions/pointActions';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CachedIcon from '@material-ui/icons/Cached';
import PauseIcon from '@material-ui/icons/Pause';

const TodoScreen = ({ todo, setTodo, taskStatus, getTodos, startTodo, stopTodo, pauseTodo, deleteTodo, getPoints, weeklyGoal, setOpenBackdrop, setIsAddComponent, setOpenConfirmation, setIdToDelete }) => {
    const todos = useSelector((state) => state.todos);
    const auth = useSelector((state) => state.auth.user);

    const updateClickHandler = (popupState) => {
        // As we are using the same form for "ADD" method, 
        // setting setTodo here will auto-prefill the different inputs
        // So that user can change only what he wants
        setTodo({ ...todo, todoDate: todo.todoDate });
        popupState.close();
        const oneModaltodo = document.querySelector(".modal__views");
        oneModaltodo.classList.add("active-modal");
        setIsAddComponent(false)
    }

    const deleteHandler = (id) => {
        setOpenBackdrop(true)
        deleteTodo(id);

        setTimeout(function () { getPoints(auth._id) }, 5000);
    }

    const startHandler = () => {
        setOpenBackdrop(true);
        const id = todo._id;
        startTodo(id);
    }

    const stopHandler = () => {
        setOpenBackdrop(true);
        const id = todo._id;
        const updatedTodo = {
            uid: auth._id,
            name: todo.name,
            todoDate: todo.todoDate,
            estimatedDuration: todo.estimatedDuration,
            endTime: new Date()
        }
        stopTodo(id, updatedTodo);

    }

    const pauseHandler = () => {
        setOpenBackdrop(true);
        const id = todo._id;
        const updatedTodo = {
            uid: auth._id,
            name: todo.name,
            todoDate: todo.todoDate,
            estimatedDuration: todo.estimatedDuration,
            pauseStartTime: new Date()
        }
        pauseTodo(id, updatedTodo);
    }

    return (
        <>
            <Card className="">
                <CardHeader
                    className="cardHeader"
                    avatar={
                        todo.isCompleted ? (
                            <CheckCircle className="isCompleted" />
                        ) : todo.outOfTime ? (
                            <CancelIcon className="notActive" />
                        ) : todo.playing ? (
                            <CachedIcon className="isRunning" />
                        ) : todo.isPaused ? (
                            <PauseIcon className="isRunning" />
                        ) : (
                            <RadioButtonUncheckedIcon className="action" />
                        )
                    }
                    action={
                        <PopupState variant="popover" popupId="demo-popup-popover" >
                            {(popupState) => (
                                <div >
                                    <MoreVertIcon {...bindTrigger(popupState)} />
                                    <Popover className="popover--container"
                                        {...bindPopover(popupState)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <Box p={2}>
                                            {todo.isCompleted ? (
                                                <Button>
                                                    <CheckCircle className="isCompleted" />
                                                </Button>
                                            ) : todo.outOfTime ? (
                                                <>
                                                    {/* <CancelIcon className="notActive" /> */}
                                                    <Button onClick={() => updateClickHandler(popupState)}>
                                                        <Create color="primary" />
                                                    </Button>
                                                </>
                                            ) : todo.playing ? (
                                                <>
                                                    <Button onClick={() => stopHandler()}>
                                                        <StopSharp className="isPlaying" />
                                                    </Button>

                                                    <Button onClick={() => pauseHandler()}>
                                                        <PauseIcon className="isPlaying" />
                                                    </Button>
                                                </>
                                            ) : todo.isPaused ? (
                                                <>
                                                    <Button onClick={() => startHandler()}>
                                                        <PlayCircleFilled className="notPlaying" />
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <Button onClick={() => startHandler()}>
                                                        <PlayCircleFilled className="notPlaying" />
                                                    </Button>
                                                    <Button onClick={() => updateClickHandler(popupState)}>
                                                        <Create color="primary" />
                                                    </Button>
                                                    <Button onClick={() => { setIdToDelete(todo._id); setOpenConfirmation(true) }}>
                                                        <Delete color="secondary" />
                                                    </Button>
                                                    {/*<Button onClick={() => deleteHandler(todo._id)}>
                                                        <Delete color="secondary" />
                                            </Button>*/}
                                                </>
                                            )}
                                        </Box>
                                    </Popover>
                                </div>
                            )}
                        </PopupState>

                    }
                    title={todo.isCompleted ? (
                        <div>
                            <h3 className="checked qualification__title"> {todo.name}</h3>
                        </div>
                    ) : todo.outOufTime ? (
                        <div>
                            <h3 className="checked qualification__title"> {todo.name}</h3>
                        </div>
                    ) : (
                        < div >
                            <h3 className="qualification__title"> {todo.name}</h3>
                            {todo.comment ? (
                                <div className="">
                                    {todo.comment}
                                </div>
                            ) : (
                                <>
                                </>
                            )}
                        </div>
                    )}
                    subheader={
                        <div>
                            <div>
                                {!todo.weeklyGoal == "" ? (
                                    <span className="inside--card">
                                        <i className="uil uil-link"></i>
                                        Objectif hebdomadaire: {weeklyGoal.estimatedNumberedGoal} {weeklyGoal.name}
                                    </span>
                                ) : (
                                    <div></div>
                                )}

                                <span className="inside--card">
                                    <i className="uil uil-calendar-alt"></i>
                                    Date: {moment(todo.todoDate, "DD/MM/YYYY").format('DD MMMM YYYY')}
                                </span>

                                <span className="inside--card">
                                    <i className="uil uil-hourglass"></i>
                                    Durée estimée: {todo.estimatedDuration} minutes
                                </span>

                                {!todo.point == 0 ? (
                                    <span className="inside--card">
                                        <i className="uil uil-award-alt"></i>
                                        Nombre de points gagnés: {todo.point} points
                                    </span>
                                ) : (
                                    <div></div>
                                )}

                                {todo.priority != null ? (
                                    <span className="inside--card">
                                        <i class="uil uil-exclamation-octagon"></i>
                                        Priorité: {todo.priority}
                                    </span>
                                ) : (
                                    <div></div>
                                )}

                                <div className="inside--card-date">
                                    {moment(todo.createdAt).fromNow()}
                                </div>
                            </div>
                        </div>
                    }
                />
            </Card>
        </>
    )
}

export default connect(null, { getTodos, startTodo, stopTodo, pauseTodo, deleteTodo, getPoints })(TodoScreen);